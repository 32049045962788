* {
    box-sizing: border-box;
}

html, body, form {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.buttonformat {
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 2vw;
}

.dateformat {
    padding-left: 2vw;
}


.boxcontainer {
    display:         flex;
    justify-content: center;
}
  

    
.box {
    float: left;
}


/*
.box:last-child::after {
    display: table;
    content: '';
    clear: both;
}
*/

.containerdivNewLine { 
    clear: both; 
    float: left; 
    display: block; 
    position: relative; 
} 

.aqua {
    background-color: aqua;
}
.pink {
    background-color: pink;
}
.green {
    background-color: green;
}
.tan {
    background-color: tan;
}
.orange {
    background-color: orange;
}
.lightgreen {
    background-color: lightgreen;
}
.lightyellow {
    background-color: lightyellow;
}
.lightcoral {
    background-color: lightcoral;
}