@import url('https://fonts.googleapis.com/css?family=Oswald');

.footer {
    font-family: 'Oswald';
    font-size: 15px;
    padding-top: 60px;
    padding-right: 40px;
    background: white;
    color: black;
    text-align: right;
}
