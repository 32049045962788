@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=Oswald);
.black {
    color: black;
    white-space: nowrap;
}

.banner {
    text-align: center;
    background: #c7ddf0;
    color: #c7ddf0;
    font-size: 24px;
    font-family: 'Oswald';
}

.footer {
    font-family: 'Oswald';
    font-size: 15px;
    padding-top: 60px;
    padding-right: 40px;
    background: white;
    color: black;
    text-align: right;
}

.devices {
    background: white;
    color: black;
    font-size: 24px;
    font-family: 'Oswald';
    font-weight: normal;
}

td {
    font-size: 20px;
    font-weight: normal;
}

.editing {
    color: red;
}

.editing:focus {
    color: red;
}

.notediting {
    color: black;
}

.notediting:focus {
    color: black;
}

.mdl {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.mdl-content {
    width: 500px;
    background-color: #fff;
}

.mdl-header, .mdl-footer {
    padding: 10px;
}

.mdl-title {
    margin: 0;
}

.mdl-body {
    padding: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.lbl {
    font-size: large;
}
.mdl {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.mdl-content {
    width: 500px;
    background-color: #fff;
}

.mdl-header, .mdl-footer {
    padding: 10px;
}

.mdl-title {
    margin: 0;
}

.mdl-body {
    padding: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.lbl {
    font-size: large;
}
.bmp {
    position: absolute;
    z-index:-1;
}
.bgred {
    background-color: white;
}
.arrows {
    font-size: 30px;
    color: #158cba;
}

.arrows:hover {
    color: #9bd2eb;
}

.arrows:active {
    color: #158cba;
}

.nowrap {
    white-space: nowrap;
}

.clr0 {
    background-color: #909090;
}
.clr1 {
    background-color: #B40000;
}
.clr2 {
    background-color: #008000;
}
.clr3 {
    background-color: #0000FF;
}
.clr4 {
    background-color: #ff991b;
}
.clr5 {
    background-color: #6A5ACD;
}
.clr6 {
    background-color: black;
}

.mdl {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.mdl-content {
    width: 500px;
    background-color: #fff;
}

.mdl-header, .mdl-footer {
    padding: 10px;
}

.mdl-title {
    margin: 0;
}

.mdl-body {
    padding: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.lbl {
    font-size: large;
}
.row1 {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 1vw;
    margin-right: 1vw;
    font-size: 24px;
    white-space: nowrap;
    font-weight: bold;
}

.center {
    text-align: center;
}

#download_spinner {
    visibility: hidden;
}
.row2 {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 1vw;
    margin-right: 1vw;
    font-size: 24px;
    white-space: nowrap;
    font-weight: bold;
}

.center {
    text-align: center;
}
.sensorName {
    font-size: 10px;
    font-family: 'Oswald';
    text-align: center;
}

.device {
    background: white;
    color: black;
    font-size: 30px;
    font-family: 'Oswald';
    width: 50%;
}

.dd {
    background: white;
    color: black;
    font-size: 10px;
    font-family: 'Oswald';
}

.datepick {
    font-size: 20px;
}


.meta {
    margin-right: 10px;
    margin-top: 26px;
}


.graphElement {
    padding-top: 10px;
}

* {
    box-sizing: border-box;
}

html, body, form {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.buttonformat {
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 2vw;
}

.dateformat {
    padding-left: 2vw;
}


.boxcontainer {
    display:         flex;
    justify-content: center;
}
  

    
.box {
    float: left;
}


/*
.box:last-child::after {
    display: table;
    content: '';
    clear: both;
}
*/

.containerdivNewLine { 
    clear: both; 
    float: left; 
    display: block; 
    position: relative; 
} 

.aqua {
    background-color: aqua;
}
.pink {
    background-color: pink;
}
.green {
    background-color: green;
}
.tan {
    background-color: tan;
}
.orange {
    background-color: orange;
}
.lightgreen {
    background-color: lightgreen;
}
.lightyellow {
    background-color: lightyellow;
}
.lightcoral {
    background-color: lightcoral;
}

.nowrap {
    white-space: nowrap;
}

.clr0 {
    background-color: #909090;
}
.clr1 {
    background-color: #B40000;
}
.clr2 {
    background-color: #008000;
}
.clr3 {
    background-color: #0000FF;
}
.clr4 {
    background-color: #ff991b;
}
.clr5 {
    background-color: #6A5ACD;
}
.clr6 {
    background-color: black;
}

.dpSensorName {
    color: black;
    font-size: 1.2vw;
    font-family: 'Oswald';
    text-align: center;
    white-space: nowrap;
    font-weight: bold;
}

.graph {
    margin-right: 20px;
    margin-left: 20px;
}

.ver {
    text-align: right;
    margin-top: 10px;
    font-size: 18px;
}

.admin {
    background: white;
    color: black;
    font-size: 24px;
    font-family: 'Oswald';
}

td {
    font-size: 20px;
    font-weight: normal;
}

.profile {
    background: white;
    color: black;
    font-size: 24px;
    font-family: 'Oswald';
}

.label {
    background: white;
    color: black;
    font-size: 20px;
    font-family: 'Oswald';
}

.bottom-text {
    background: white;
    color: black;
    font-size: 18px;
    font-family: 'Oswald';
}

.oneline {
    white-space: nowrap;
}
.showlicense {
    background: white;
    color: black;
    font-size: 24px;
    font-family: 'Oswald';
}


* {
    box-sizing: border-box;
}

html, body, form {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.buttonformat {
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 2vw;
}

.dateformat {
    padding-left: 2vw;
}


.boxcontainer {
    display: flex;
    justify-content: space-around;
}
  
   
.box {
    display: flex;
    font-family: 'Oswald';
    font-size: 20px;
}


.c1 {
    background-color: aqua;
}
.c2 {
    background-color: pink;
}
.c3 {
    background-color: green;
}
.c4 {
    background-color: tan;
}
.c5 {
    background-color: orange;
}
.c6 {
    background-color: lightgreen;
}
.c7 {
    background-color: lightyellow;
}
.c8 {
    background-color: lightcoral;
}
