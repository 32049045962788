@import url('https://fonts.googleapis.com/css?family=Oswald');

.devices {
    background: white;
    color: black;
    font-size: 24px;
    font-family: 'Oswald';
    font-weight: normal;
}

td {
    font-size: 20px;
    font-weight: normal;
}

.editing {
    color: red;
}

.editing:focus {
    color: red;
}

.notediting {
    color: black;
}

.notediting:focus {
    color: black;
}
