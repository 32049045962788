@import url('https://fonts.googleapis.com/css?family=Oswald');

.ver {
    text-align: right;
    margin-top: 10px;
    font-size: 18px;
}

.admin {
    background: white;
    color: black;
    font-size: 24px;
    font-family: 'Oswald';
}

td {
    font-size: 20px;
    font-weight: normal;
}
