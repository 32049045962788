@import url('https://fonts.googleapis.com/css?family=Oswald');


.dpSensorName {
    color: black;
    font-size: 1.2vw;
    font-family: 'Oswald';
    text-align: center;
    white-space: nowrap;
    font-weight: bold;
}

.graph {
    margin-right: 20px;
    margin-left: 20px;
}
