.arrows {
    font-size: 30px;
    color: #158cba;
}

.arrows:hover {
    color: #9bd2eb;
}

.arrows:active {
    color: #158cba;
}

.nowrap {
    white-space: nowrap;
}

.clr0 {
    background-color: #909090;
}
.clr1 {
    background-color: #B40000;
}
.clr2 {
    background-color: #008000;
}
.clr3 {
    background-color: #0000FF;
}
.clr4 {
    background-color: #ff991b;
}
.clr5 {
    background-color: #6A5ACD;
}
.clr6 {
    background-color: black;
}
