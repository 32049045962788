@import url('https://fonts.googleapis.com/css?family=Oswald');

.profile {
    background: white;
    color: black;
    font-size: 24px;
    font-family: 'Oswald';
}

.label {
    background: white;
    color: black;
    font-size: 20px;
    font-family: 'Oswald';
}

.bottom-text {
    background: white;
    color: black;
    font-size: 18px;
    font-family: 'Oswald';
}

.oneline {
    white-space: nowrap;
}