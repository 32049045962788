@import url('https://fonts.googleapis.com/css?family=Oswald');

.sensorName {
    font-size: 10px;
    font-family: 'Oswald';
    text-align: center;
}

.device {
    background: white;
    color: black;
    font-size: 30px;
    font-family: 'Oswald';
    width: 50%;
}

.dd {
    background: white;
    color: black;
    font-size: 10px;
    font-family: 'Oswald';
}

.datepick {
    font-size: 20px;
}


.meta {
    margin-right: 10px;
    margin-top: 26px;
}


.graphElement {
    padding-top: 10px;
}
