@import url('https://fonts.googleapis.com/css?family=Oswald');

* {
    box-sizing: border-box;
}

html, body, form {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.buttonformat {
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 2vw;
}

.dateformat {
    padding-left: 2vw;
}


.boxcontainer {
    display: flex;
    justify-content: space-around;
}
  
   
.box {
    display: flex;
    font-family: 'Oswald';
    font-size: 20px;
}


.c1 {
    background-color: aqua;
}
.c2 {
    background-color: pink;
}
.c3 {
    background-color: green;
}
.c4 {
    background-color: tan;
}
.c5 {
    background-color: orange;
}
.c6 {
    background-color: lightgreen;
}
.c7 {
    background-color: lightyellow;
}
.c8 {
    background-color: lightcoral;
}