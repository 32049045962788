.mdl {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.mdl-content {
    width: 500px;
    background-color: #fff;
}

.mdl-header, .mdl-footer {
    padding: 10px;
}

.mdl-title {
    margin: 0;
}

.mdl-body {
    padding: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.lbl {
    font-size: large;
}