.row2 {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 1vw;
    margin-right: 1vw;
    font-size: 24px;
    white-space: nowrap;
    font-weight: bold;
}

.center {
    text-align: center;
}